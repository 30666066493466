import React from 'react';
import Faq from '../components/FAQ';

function FaqPage() {
  return (
    <>
      <Faq />
    </>
  );
}

export default FaqPage;
