export const aboutSection = {
  id: "about",
  lightBg: false,
  backgroundImage: require("../../images/jungle.jpg"),
  lightText: true,
  lightTextDesc: true,
  topLine: "Get Ready",
  headline: "All roads lead to Tulum in March 2024",
  description:
    "Mark your calendars and pack your party shoes because a spectacular celebration awaits you! Join me in embracing the sun-kissed beaches and vibrant vibes of Tulum, Mexico as we celebrate my 30th birthday in style.",
  link: "/logistics",
  buttonLabel: "What's the plan?",
  imgStart: true,
  alt: "Logo",
  dark: true,
  primary: true,
  darkText: false,
};

export const hotelSection = {
  id: "hotel",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Hotel",
  headline: "Amansala",
  description: "We've secured a slice of paradise at the fabulous Amansala Hotel, where luxury and relaxation go hand in hand. Be sure to check out the Logistics & FAQ pages and RSVP before booking a room in our room block!",
  link: "https://www.amansala.com/matts-birthday",
  buttonLabel: "Book a Room",
  imgStart: false,
  img: require("../../images/amansala-white.png"),
  alt: "Amansala Logo",
  dark: true,
  primary: true,
  darkText: false,
};

export const excursionsSection = {
  id: "excursions",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Excursions",
  headline: "Let's go on an adventure!",
  description: "Our adventure-packed itinerary includes exploring the awe-inspiring Gran Cenote and the mystical Cenote Calavera, working out at the exhilarating Tulum Jungle Gym, and indulging in retail therapy with a dash of beachside bliss at Paradise Beach.",
  toSection: "excursion-list",
  buttonLabel: "Dive in",
  imgStart: false,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: false,
  primary: false,
  darkText: true,
};

export const beachClubsSection = {
  id: "beach-clubs",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Beach Clubs",
  headline: "Let's get this party started!",
  description: "When it's time to unwind and sip on something delicious, we've handpicked the coolest beach clubs for our crew. Dance the day away at Taboo, revel in the trendy elegance of Bagatelle, and immerse yourself in the enchanting vibes of Vagalume Tulum. And let's not forget the beachfront paradise awaiting us at Ahau Tulum.",
  toSection: "beach-club-list",
  buttonLabel: "Let's go!",
  imgStart: true,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: true,
  primary: true,
  darkText: false,
};

export const foodSection = {
  id: "food",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Food",
  headline: "I'm getting hungry just thinking about it...",
  description: "Hungry? Prepare your taste buds for a culinary journey like no other. We've curated a selection of mouthwatering options for your gastronomic pleasure. From the fusion delights of Funky Geisha to the unforgettable flavors at Bagatelle, the ethereal experience of Kin toh at Azulik, the beachfront luxury of Be Tulum, and the vibrant ambiance of Gitano, your senses will thank you.",
  toSection: "food-list",
  buttonLabel: "What's on the Menu?",
  imgStart: false,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: false,
  primary: false,
  darkText: true,
};

export const barsSection = {
  id: "bars",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Bars",
  headline: "Did somebody say tequila?",
  description: "As the sun bids farewell, it's time to ignite the nightlife of Tulum. Raise your glasses at Todos Santos, dance the night away at Boa, or immerse yourself in the wild energy of Casa Jaguar. The night is yours to own!",
  toSection: "bar-list",
  buttonLabel: "Water is your friend",
  imgStart: true,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: true,
  primary: true,
  darkText: false,
};

export const nightClubsSection = {
  id: "night-clubs",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Night Clubs",
  headline: "Boots in the line for the cats",
  description: "When the sun goes down, the party is just getting started. We've got the hottest nightclubs in Tulum on our radar, and we're ready to dance the night away. From the wild energy of Gitano to the vibrant vibes of Rosa Negra, we're ready to celebrate in style.",
  toSection: "night-club-list",
  buttonLabel: "No sleep, another club...",
  imgStart: false,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: false,
  primary: false,
  darkText: true,
};

export const closingSection = {
  id: "closing",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "LFG",
  headline: "Can't wait to see you there!",
  description: "Prepare for an extraordinary 30th birthday bash, filled with laughter, adventure, and unforgettable memories. Tulum awaits, and together, we'll create a celebration that will be sure to go down as one of the best chapters in the Tell All. Let's make this milestone one for the books!",
  link: "https://posh.vip/e/mdx30",
  buttonLabel: "RSVP",
  imgStart: true,
  img: require("../../images/logo-round.png"),
  alt: "Logo",
  dark: false,
  primary: false,
  darkText: true,
};
