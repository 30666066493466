import React from 'react';
import Logistics from '../components/Logistics';

function LogisticsPage() {
  return (
    <>
      <Logistics />
    </>
  );
}

export default LogisticsPage;
